import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { IconButton, Tooltip, Grid, Typography, Button, Checkbox } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { RestComponent } from 'react-frontend-utils' 
import { ThemeColors } from '../Theme'

import { Member, Applicant } from '../models/Person';
import { ImageFunctions } from '../utils/Image'


// SYNC PAGE 2 - Receives the Membership ID from Page 1, and asks the backend for a PPSyncPrepare object:
// 
//  PPSyncPrepare from backend:
//  
//  {   membership         //Poolpass Membership data - null for new
//      household          //extracted Household data from Application
//      matchedPair        //array of {member, applicant}
//      matchedApplicants  //array of applicants
//      orphanedMembers    //array of members
//      canPrint           //true if printing for the community is enabled    
//   }
//
//
//   When finished: passes "Sync data" object to Page 3:
//   
//   {
//      membership:           //Poolpass Membership data
//      replace:              //true if we're replacing the entire Membership
//      household:            //extracted Household data from Application
//      changingMembers:      //array of pruned Member objects (only changing Members) - 
//                                  with a field that identifies the matched Applicant (which is null when not matched), or marked for delete
//                                  when replacing, pass the empty array []
//      newMembers:           //array of pruned Applicant objects, that will be added to the Membership
//      abandonedApplicants:  //count of Applicants abandoned (not added or matched)
//      unchangedMembers:     //count of Members left unchanged
//   }
//
//



//Fixed dimensions for rendering a draggable person element
const personWidth = 220;
const personHeight = 150;       

const personMargin = 8;
const personImageScale = 2.3;

const styles = {

        personContainer: {
            padding: personMargin/2,
            height: personHeight,
            width: personWidth
        },
        personInset: {
            border: '1px solid black',
            backgroundColor: 'white',
            margin: personMargin/2,
            padding: 4,
            borderRadius: '8px',
            height: '90%'
        },
        applicant : {
            backgroundColor: ThemeColors.applicantTan
        },
        member: {
            border: '1px solid ' + ThemeColors.appBarBackground,
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19)'
        },
        memberbucket: {
            display: 'flex',            
            marginBottom: 10,
            marginRight: 10,
            width: personWidth*2 + 2*personMargin + 4
        },
        memberIDCircled: {
            fontWeight: 'bold', 
            textAlign: 'center', 
            float: 'left', 
            position: 'absolute',
            lineHeight: '24px',
            fontSize: '11',
            border: '1px solid gray',  
            width: 24, 
            height: 24, 
            marginTop: -20,
            borderRadius: '50%',
            transform: 'scale(0.9)'
        },
        container: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: ThemeColors.containerColor,
            border: '1px solid gray',
            borderRadius: '10px',
            paddingTop: personMargin/2,
            paddingBottom: personMargin/2,
            marginLeft: 10,
            marginRight: 10,
            width: personWidth + 2*personMargin
        },
        newMemberContainer: {       //added styles to container
            border: '1px solid green',     
            backgroundColor: '#F5FFEB'
        },
        link: {
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            zIndex: -1,
            fontWeight: 'bold',
            textAlign: 'center',
            height: personHeight/3,
            width: personMargin,
            marginTop: personMargin/2 + personHeight/3,
            marginLeft: -personMargin,
            marginRight: -personMargin
        },
        checkbox: {
            color: 'gray'
        },
        checkboxDisabled: {
            color: 'lightGray'
        }
};
  


export class SyncPage2 extends RestComponent {
  
     
    _id;   //Application id 
    _membershipID;   //Membership ID, from Page 1
    
    _nextPageCallback;
    _prevPageCallback;
    
    _totalApplicants = 0;
    _matchedApplicants = 0;
    
    constructor(props) {
        super(props);
    
        this._id = props.id;        //application id
        this._membershipID = props.membershipID;    
        
        this._nextPageCallback = props.nextPageCallback;  //callback that moves to Page 3
        this._prevPageCallback = props.prevPageCallback;  //callback that returns to Page 1
        
        this.state.membership = null;   //Poolpass Membership data
        this.state.household = null;    //extracted Household data from Application
        
        this.state.applicants = [];     //array of Applicant objects, that aren't matched with Members
        
        this.state.members = [];        //array of Member objects - with a field that identifies the matched Applicant (which is null when not matched)

        this.state.newMembers = [];     //array of Applicant objects, that will be added to the Membership

        this.state.canPrint = false;
    }
    
    
    
    /**
     * When the page loads, immediately fetch the Memberships for the Application
     */
    componentDidMount() {
        super.componentDidMount(); 
        
        // Browser back button - go back to Application List
       window.addEventListener("popstate", this._prevPageCallback);
       
       if (this._membershipID)  //if we arrive with a existing Membership ID, pull Membership/Member data and match
            this._matchMembers();
        else
            this._getApplicants();
    }
   
    componentWillUnmount() {
        super.componentWillUnmount();       
        window.removeEventListener("popstate", this._prevPageCallback);
    }
    
    
    //Called to fetch Membership and Member data from the selected Membership ID, along with the Application data to sync and Applicant data to match against Members
    _matchMembers = () => {
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + this._id + "/ppsync/memberships/" + this._membershipID, {},
                            this._matchMembersCallback, this._fetchErrorCallback);   
    }
   
    

    _matchMembersCallback = (response) => {
        
        //Response maps to the PPSyncPrepare object from the backend
        if (response) {
            
            let membership = response.membership;
            let household = response.household;
            
            //Create members from the Matched Applicants - each Member's applicant field is populated
            let members = response.matchedApplicants.map(matchedPair => Member.fromPair(matchedPair, response.canPrint));
            
            this._matchedApplicants = members.length;
            this._totalApplicants = members.length;
            
            //Get the orphaned Members
            const orphans = response.orphanedMembers.map(orphan => Member.fromOrphan(orphan));
            
            //Combine all Members
            members = members.concat(orphans);
            
            //Sort Members by member id
            members.sort((a, b) => { return a.id - b.id; }); 
            
            //Get the new Applicants
            const applicants = response.newApplicants.map(applicant => new Applicant(applicant, response.canPrint));
            this._totalApplicants += applicants.length;

            
            this.setState({membership: membership, household: household, members: members, applicants: applicants, canPrint: response.canPrint});

            /* Comment this out for now - pending AP2-I70
            const limitedMembers = members.filter(member => member.limited);
            if (limitedMembers) {
                this.showConfirmAlert("Notice", "There are " + limitedMembers.length + " limited Members currently on this Membership. If they were from a previous Application, you may want to mark them for deletion.", 'black');
            }
            */

        }
        
        this.decrementBusy();
    }
    
    
    //Called when this is a new Membership, simply extract the Applicant data and Application fields - response goes to matchMembersCallback with many fields null
    _getApplicants = () => {
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + this._id + "/ppsync/newMembership", {},
                            this._matchMembersCallback, this._fetchErrorCallback);   
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
        this.setState({fetchInProgress: false}); //fetching failed
    }
  
  
    //Find the member in the state members array by the member id
    _findMemberById = (id) => {
        for (let member of this.state.members) {
            if (member.id === id)
                return member;
        }
        console.error("Cannot find Member " + id);
    }
    
    //Find the applicant in the state applicant array by the index
    _findApplicantByIndex = (index) => {
        for (let applicant of this.state.applicants) {  //check applicants
            if (applicant.index === index) 
                return applicant;
        }
        console.error("Cannot find Applicant " + index);
    }
    
    //Find the applicant in the newMembers array by the index
    _findNewMemberByIndex = (index) => {
        for (let newMember of this.state.newMembers) {  //check applicants
            if (newMember.index === index) 
                return newMember;
        }
        console.error("Cannot find New Member " + index);
    }
  
  
    //Toggle the member as marked for deletion, and if it has a matched Applicant, remove it and put it back to the applicants container
    _markMemberForDelete = (member) => {
        member.markedForDelete = !member.markedForDelete;
        
        if (member.matchedApplicant)     //if a match exists, move it back to the Applicants container at the end
            this.state.applicants.push(member.matchedApplicant);
            
        member.matchedApplicant = null;  //clear
        
        this.forceUpdate();     
    }
    
    //Toggle the Applicant as marked to overwrite the matched Member image, or keep when creating new. If unselected, and no photo exists, the avatar will be used
    _keepApplicantImage = (applicant) => {
        applicant.keepImage = !applicant.keepImage;
        this.forceUpdate();     
    }
    
    //Toggle the Applicant as marked to print their pass. If unselected
    _printApplicantPass = (applicant) => {
        applicant.printPass = !applicant.printPass;
        this.forceUpdate();     
    }
    
    
    _replaceMembership = (event) => {
        
        //Check members - none can have a matchedApplicant if we are replacing
        for (let member of this.state.members) {
        
            if (member.matchedApplicant) {
                this.showConfirmAlert("Error", "You cannot match Applicants to Members when replacing the entire Membership. Drag those Applicants into one of the Applicants containers.", 'red');
                return;
            }     
        }
        
        if (this.state.newMembers.length === 0)  {
            this.showConfirmAlert("Error", "You have not added any new Applicants", 'red');
            return;
        }     
        
        this._continue(null, true);
    }
  
    //Consolidate all of the desired changes into a sync data object and pass this to page 3. Any Applicants not marked for matching or 
    //as new Members are left here, not to continue into Step 3.  If the replace param is true, then the Membership is marked for replacement.
    _continue = (event, replace = false) => {
        
        //Creating new Membership, must have Members
        if (!this.state.membership && this.state.newMembers.length === 0)  {
            this.showConfirmAlert("Error", "You have not added any new Applicants", 'red');
            return;
        }
        
        //Prune out unecessary fields, backend only needs a few items to sync
        
        let changingMembers = []; 
        if (!replace) {  //if we are replacing the Membership entirely, leave existing Members as null
           
            //Filter out any Members that are not changing (not matched, or not marked for delete)
            changingMembers = this.state.members.filter(member => member.matchedApplicant || member.markedForDelete);
                        
            changingMembers = changingMembers.map(member => {

                const matchedApplicant = member.matchedApplicant ? {index: member.matchedApplicant.index, 
                                                                    keepImage: member.matchedApplicant.keepImage, 
                                                                    printPass: member.matchedApplicant.printPass}  : null;

                //Prune the Member object down to the needed fields
                return {id: member.id, 
                        lastTransaction: member.lastTransaction,
                        matchedApplicant: matchedApplicant, 
                        markedForDelete: member.markedForDelete
                        };
            });
        }
       
        const unchangedMembers = this.state.members.length - changingMembers.length;
        
        const newMembers = this.state.newMembers.map(applicant => {
            return {index: applicant.index, keepImage: applicant.keepImage, printPass: applicant.printPass};
        });
        
        
        const syncData = {
            membership: this.state.membership,                  //Poolpass Membership data
            replace: replace,                                   //true to entirely replace this Membership
            household: this.state.household,                    //extracted Household data from Application
            changingMembers: changingMembers,                   //array of pruned Member objects - with a field that identifies the matched Applicant (which is null when not matched)
            newMembers: newMembers,                             //array of pruned Applicant objects, that will be added to the Membership
            abandonedApplicants: this.state.applicants.length,  //number of Applicants left abandoned (not matched or added)
            unchangedMembers: unchangedMembers                  //number of Members left unchanged 
        };
        
        this._nextPageCallback(syncData);
    }
  
  
    _onDragEnd = (result) => {
        
        const { destination, source, draggableId } = result;

        if (!destination) //dropping over nothing
            return;
   
        //dropping over itself
        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;
        
        let removedApplicant;

        
        //-------------- REMOVAL -------------------
        //Being removed from a Member bucket
        if (source.droppableId.startsWith("member")) {
            const memberId = parseInt(source.droppableId.split("-")[1]);  //find the id of the Member for this bucket
            const member = this._findMemberById(memberId);  
            removedApplicant = member.matchedApplicant;
            member.matchedApplicant = null; //remove our match
        }
        //Being removed from the Applicant container
        else if (source.droppableId === "applicants") {
            removedApplicant = this._findApplicantByIndex(parseInt(draggableId));  //get the Applicant being removed - the id is the Applicant index
            this.state.applicants.splice(source.index, 1);  //Remove the applicant from array
        }
        else if (source.droppableId === "newMembers") {
            removedApplicant = this._findNewMemberByIndex(parseInt(draggableId));  //get the Applicant being removed - the id is the Applicant index            
            this.state.newMembers.splice(source.index, 1);  //Remove the newMember from array
        }
    
        //-------------- INSERTION -------------------
        //Adding to the Applicants container
        if (destination.droppableId === "applicants") {
            this.state.applicants.splice(destination.index, 0, removedApplicant);    
        }
        
        else if (destination.droppableId.startsWith("member")) {  //dropping onto a member bucket
            const memberId = parseInt(destination.droppableId.split("-")[1]);  //find the id of the Member for this bucket
            const member = this._findMemberById(memberId);
            if (member.matchedApplicant)     //if a match exists, move it back to the Applicants container at the end (replacing it)
                this.state.applicants.push(member.matchedApplicant);
            
            member.matchedApplicant = removedApplicant;            //set the new one    
        }
        else if (destination.droppableId.startsWith("newMembers")) {
            this.state.newMembers.splice(destination.index, 0, removedApplicant);    
        }            
 
        this.forceUpdate();
    }
  
 
    render() {
       
        if (this.state.isBusy)
            return this.getBusyComponent('center', {marginTop: 20});
       
        const containerHeight = Math.max(this._totalApplicants, 1) * (personHeight + personMargin) + personMargin;    
            
            
        const hasMembers = this.state.membership && this.state.members.length > 0;    
            
        let message;
        if (!this.state.membership) {  //new Membership from Application
            message = "You are creating a new Membership from this Application. Drag the Applicants for the Membership to the New Members container. Any Applicant remaining in the Applicants container will be ignored.";
        }
        else if (this._matchedApplicants > 0) {
            message = "We pre-matched " + this._matchedApplicants + " Applicant" + (this._matchedApplicants > 1 ? "s" : "") + " for you. You may drag Applicants around to match them with the correct Member, " + 
                      "or move them to the New Applicants container to be added to the Membership. Any Applicant remaining in the Applicants container will be ignored.";
        }
        else if (this.state.members.length === 0)
            message = "This Membership does not have any Members. Drag the Applicants for the Membership to the New Applicants container.";
        else {
            message = "It appears like none of the Applicants match well with existing Members. You may drag Applicants around to match them with the correct Member, " +
                      "or completely replace this Membership.";
        }
        
        if (this._totalApplicants === 0)
            message = "There are no Applicants on the Application. You can press Continue to Update to proceed to sync Membership information.";

        return (
            <Fragment>
                {this.getConfirmAlertComponent()}
                    
                <div style={{display: 'flex', justifyContent: 'space-between', gap: 20, marginRight: 10, marginBottom: 20, marginLeft: 'auto'}}>

                    <Typography variant="body1" style={{color: ThemeColors.mediumGray}}>{message}</Typography>  

                    <div>
                        <div style={{display: 'flex', justifyContent: 'right'}}>
                            {this.state.membership ?
                                    <Tooltip title="Delete this Membership and all of its Members and replace it with a new Membership containing Applicants to Add">
                                        <Button variant='outlined' style={{borderColor: ThemeColors.addColor, color: ThemeColors.addColor, marginRight: 10, width: 200}}
                                                onClick={this._replaceMembership}>
                                            Delete and Replace
                                        </Button>
                                </Tooltip>   
                                : 
                                    null
                                }
                                <Tooltip title={"Go back and select a" + (this.state.membership ? " different" : "n existing") + " Membership"}>
                                    <Button variant='outlined' style={{borderColor: ThemeColors.darkGray, color: ThemeColors.darkGray, marginRight: 10, width: 200}}
                                            onClick={this._prevPageCallback} startIcon={<ArrowBackIosIcon/>}>
                                        Go Back
                                    </Button>
                            </Tooltip> 
                                <Tooltip title={"Proceed with Membership " + (this.state.membership ? "update" : "creation")}>
                                    <Button variant='outlined' style={{borderColor: 'green', color: 'green', width: 200}}
                                            onClick={this._continue} endIcon={<ArrowForwardIosIcon/>}>
                                        {this.state.membership ? "Continue Update" : "Continue Create"}
                                    </Button>
                            </Tooltip> 
                        </div>
                    </div>
                </div>
                        
                <DragDropContext onDragEnd={this._onDragEnd}>
                
                    <div style={{display: 'flex', justifyContent: hasMembers ? 'space-between' : 'space-around'}}>
        
                        {hasMembers ? 
                            <div>
                                <Tooltip title="Members matched with an Applicant will have their data updated. Members with the trash can selected will be deleted. Other will be left alone.">
                                    <Typography variant='h6' align='center' style={{marginBottom: 10, }}>{"Current Members ⬄ Matched Applicants"}</Typography>
                                </Tooltip>
                                {this.state.members.map(member => <MemberBucket key={member.id} member={member} markMemberForDelete={this._markMemberForDelete} keepApplicantImage={this._keepApplicantImage} canPrint={this.state.canPrint} printApplicantPass={this._printApplicantPass}/>)}                
                            </div>
                            :
                            null
                        }
                        
                        <div>
                            <Tooltip title="Applicants that remain here will not be added to the Membership">
                                <Typography variant='h6' align='center' style={{marginBottom: 10}}>Applicants not Added</Typography>
                            </Tooltip>
                            <div style={{...styles.container, height: containerHeight}}>
                                <Droppable droppableId={"applicants"}>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {this.state.applicants.map((applicant, index) => <ApplicantDraggable key={applicant.index} applicant={applicant} index={index} keepApplicantImage={this._keepApplicantImage} canPrint={this.state.canPrint} printApplicantPass={this._printApplicantPass}/>)}                                       
                                            {provided.placeholder}
                                        </div>                                    
                                    )}
                                </Droppable>
                            </div>
                        </div>
                        
                        <div style={{justifyContent: 'flex-end'}}>
                            <Tooltip title="Applicants added here will become new Members of the Membership">
                                <Typography variant='h6' align='center' style={{marginBottom: 10}}>Applicants to Add</Typography>
                            </Tooltip>
                            <div style={{...styles.container, ...styles.newMemberContainer, height: containerHeight}}>
                                <Droppable droppableId={"newMembers"}>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {this.state.newMembers.map((newMember, index) => <ApplicantDraggable key={newMember.index} applicant={newMember} index={index} keepApplicantImage={this._keepApplicantImage} canPrint={this.state.canPrint}  printApplicantPass={this._printApplicantPass}/>)}                                       
                                            {provided.placeholder}
                                        </div>                                    
                                    )}
                                </Droppable>    
                            </div>
                        </div>
        
        
                    </div>    
                </DragDropContext>
                
                

            </Fragment>    
        );
    }
  
}

export default withCookies(withRouter(SyncPage2));



//A MemberBucket Renders a Member with a Droppable component to the right that allows matching with a Draggable "Applicant".  Show a link when matched.
const MemberBucket = (props) =>  {
        
        
    return <div style={styles.memberbucket}>

                <Person person={props.member} isMember={true} markForDelete={props.markMemberForDelete}/>

                <div style={{...styles.link, backgroundColor: props.member.matchedApplicant ? ThemeColors.memberDragOver : 'white'}}/>

                <Droppable droppableId={"member-" + props.member.id} isDropDisabled={props.member.markedForDelete}>
                    {(provided, snapshot) => {
                        
                        //Give color to the drop area when Applicant drags over
                        const dropAreaStyle = snapshot.isDraggingOver ? {backgroundColor: ThemeColors.memberDragOver, borderRadius: '10px'} : {};
                        
                        //If the item being dragged over the droppable area is not the one that is matched, the one that is currently matched is being replaced
                        let isBeingReplaced = false;
                        if (snapshot.isDraggingOver && props.member.matchedApplicant && parseInt(snapshot.draggingOverWith) !== props.member.matchedApplicant.index) {
                           isBeingReplaced = true;
                        }
                        
                        return <div ref={provided.innerRef} {...provided.droppableProps} style={dropAreaStyle}>

                                    {props.member.matchedApplicant ? 
                                        <ApplicantDraggable applicant={props.member.matchedApplicant} isBeingReplaced={isBeingReplaced} index={0} keepApplicantImage={props.keepApplicantImage} canPrint={props.canPrint} printApplicantPass={props.printApplicantPass} />
                                        : null
                                    }
                                    {provided.placeholder}
                                </div>;
                    }}
                </Droppable>

            </div>;
    
};
 
 
//A ApplicantDraggable Renders an Applicant with a Draggable component that can be dragged into a MemberBucket or to new/ignore containers
const ApplicantDraggable = (props) => {

    return  <Draggable draggableId={props.applicant.index.toString()} index={props.index}>
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps}>        
                        <Person person={props.applicant} isMember={false} keepImage={props.keepApplicantImage} canPrint={props.canPrint} printPass={props.printApplicantPass}
                                isDragging={snapshot.isDragging} 
                                isBeingReplaced={props.isBeingReplaced}
                                printPassType={props.applicant.printPassType}/>
                    </div>
                )}
            </Draggable>;
     
};


//Renders an Applicant or a Member - using common fields
const Person = (props) => {
    
    const specificStyle = props.isMember ? {...styles.member} : {...styles.applicant};  //copy
    
    if (props.isDragging)
        specificStyle.border = '2px solid black'; //only applicants are dragged
    
    if (props.isBeingReplaced)
        specificStyle.opacity = '20%';
    
    if (props.person.markedForDelete) {  //marked for deletion (Member subclass only!)
        specificStyle.border = '3px solid red'; 
        specificStyle.boxShadow = 'none';
    }
    
    const deleteIcon = props.person.markedForDelete ? <RestoreFromTrashIcon/> : <DeleteIcon/>;
    const trashTooltipTitle = props.person.markedForDelete ? "Restore (Unmark Member for deletion)" : "Mark Member to be deleted";
            
    const imageTooltipTitle = "When checked, Applicant image will be kept (overwriting Member image, when matched). Otherwise, Applicant image will be ignored (keeping Member image, when matched).";

    let lowerLeftText;
    if (!props.isMember) //for applicants, show the pass type                  
        lowerLeftText = <Tooltip title={props.printPassType}>
                            <Typography variant='body2' style={{marginTop: -3, fontStyle: 'italic', fontSize: 10}}>{props.printPassType}</Typography>
                        </Tooltip>
    else  //for members, show the barcode
        lowerLeftText = <Typography variant='body2' style={{marginTop: -5, fontFamily: 'monospace'}}>{props.person.barcode ? props.person.barcode : ""}</Typography>

    const limited = props.isMember && props.person.limited;

    return <div style={styles.personContainer}>
                <div style={{...styles.personInset, ...specificStyle}}>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            {ImageFunctions.memberImageURL(props.person, personImageScale)}
                            {lowerLeftText}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body2' align='right' style={{marginTop: 2, lineHeight: '85%'}}>{props.person.firstName}</Typography>
                            <Typography variant='body2' align='right'>{props.person.lastName}</Typography>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                {limited ? <Typography variant='body2' align='left' style={{fontSize: 11, fontStyle: 'italic', paddingLeft: 2, paddingRight: 4, borderRadius: 2, backgroundColor: ThemeColors.limitedBlue, color: 'white'}}>Limited</Typography> : null}
                                <Typography variant='body2' align='right' style={{fontSize: 12}}>{"AGE " + props.person.ageString()}</Typography>
                            </div>
                            <div style={{textAlign: 'right', marginRight: 3, marginTop: props.isMember? 30 : 20}}>
                                <Typography variant='body2' align='center' style={styles.memberIDCircled}>{props.isMember ? props.person.id : props.person.index}</Typography>
                                {props.isMember ? /* Only Member subclass can be deleted*/
                                    <Tooltip title={trashTooltipTitle}>
                                        <IconButton edge='end' onClick={() => props.markForDelete(props.person)}>
                                            {deleteIcon}
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    null
                                }
                            </div>   
                            {props.isMember ?  null /* Only Member subclass has id field and can be marked for deletion*/                                        
                                :   /*Only Applicants can have their image selected to be kept, pass printed, and pass type displayed*/  
                                <div style={{marginTop: 10}}>
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end', marginRight: -5}}>
                                        <Typography variant='body2' style={{fontSize: 11, marginRight: -6}} align='right'>Use Image</Typography>
                                        <Tooltip title={imageTooltipTitle}>
                                            <Checkbox checked={props.person.keepImage} size='small' onChange={() => props.keepImage(props.person)} style={styles.checkbox}/>                                  
                                        </Tooltip>
                                    </div>   
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end', marginRight: -5, marginTop: -16}}>
                                        <Typography variant='body2' style={{fontSize: 11, marginRight: -6, color: props.canPrint ? 'black' : 'gray'}} align='right'>Print Pass</Typography>
                                        <Tooltip title="This Applicant will receive a printed pass">
                                            <Checkbox disabled={!props.canPrint} checked={props.person.printPass} size='small' onChange={() => props.printPass(props.person)} style={props.canPrint ? styles.checkbox : styles.checkboxDisabled}/>                                  
                                        </Tooltip>
                                    </div>   
                                </div>
                            }
                         </Grid>
                    </Grid>
                    
                    
                </div>
            </div>;
};