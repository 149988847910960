import React, { Fragment, useState } from 'react';
    
import { Typography, TextField, Button, Checkbox, Tooltip, IconButton } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PanToolIcon from '@material-ui/icons/PanTool';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { ThemeColors } from '../Theme';

import { Application } from '../models/Application'
import { fieldCalculator } from '../calculations/FieldCalculator';

import FieldCalculationTooltip, { fieldCalculationHelp } from '../calculations/FieldCalculationTooltip';


//Used to display a Calculation Expression with a button to Evaluate and display its results.  Pressing the Evaluate button sends the server the infix 
//expression, the server converts to postfix and sends back, and then the fieldCalculator evaluates and displays the result.
function CalcTextField(props) {
  
    const [name, setName] = useState(props.calculation.resultName); 
    const [expression, setExpression] = useState(props.calculation.infixExpression);    
    const [isPaymentLineItem, setIsPaymentLineItem] = useState(props.calculation.isPaymentLineItem);
    const [isWebhookItem, setIsWebhookItem] = useState(props.calculation.isWebhookField);
    const [addsLimitedMembers, setAddsLimitedMembers] = useState(props.calculation.addsLimitedMembers);
    const [incrementsGuestPasses, setIncrementsGuestPasses] = useState(props.calculation.incrementsGuestPasses);
    const [attribute, setAttribute] = useState(props.calculation.attribute);
    const [evalResult, setEvalResult] = useState(null);
    const [self, setSelf] = useState(null);
    
    //Called after fetch from backend gets the postfix expression
    const onConvertDone = (response) => {
        try {
            if (response.result) { //result, if call was sucessful, is a postfix expression 
                
                let selfField = null;
                if (self) {  //specifying a specific self
                    const found = Application.findFieldByName(props.fieldTree, self);
                    if (!found)
                        throw new Error("Cannot find field named \"" + self + "\" for self");
                    selfField = found.field;
                }
                const calcValue = fieldCalculator(response.result, props.fieldTree, props.calcResults, selfField);
                setEvalResult({result: calcValue, error: null});
                props.calculation.result = calcValue;
            }
            else if (response.error) { //error, if call failed, is an error message
                setEvalResult({result: null, error: response.error});
                props.calculation.result = null;                
            }
                
        } catch (error) {
            setEvalResult({result: null, error: error.toString()});  
            props.calculation.result = null;
        }
    };
    
    
    //Evaluate button pressed, first convert infix to postfix, then calculate
    const evaluate = () => {
        props.convertToPostifx(props.calculation.infixExpression, onConvertDone);  //call function which asks server to compute postfix from infix
    };
    
    const color = props.isDragging ? ThemeColors.appBarBackground : ThemeColors.templateBackground;

    return (
        <div style={{display: 'flex', marginBottom: 5, marginRight: 13, border: '1px solid ' + color, backgroundColor: 'white'}}>
            <div style={{flexGrow: 2, padding: 10}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 5, width: '100%', marginBottom: 8, marginTop: 5}}>
                    <TextField label="Result Name"
                            variant="outlined" 
                            size="small"
                            style={{flexGrow: 2, maxWidth: 250, backgroundColor: 'white'}}
                            value={name} 
                            InputLabelProps={{ shrink: true }} 
                            onChange={(event) => {
                                const userValue = event.target.value;
                                setName(userValue);
                                props.calculation.resultName = userValue;
                            }}                               
                    />
                    <TextField label="Expression"
                            variant="outlined" 
                            size="small"
                            style={{flexGrow: 2, backgroundColor: 'white'}}
                            value={expression} 
                            InputLabelProps={{ shrink: true }} 
                            onChange={(event) => {
                                const userValue = event.target.value;
                                setExpression(userValue);
                                props.calculation.infixExpression = userValue;
                            }}                               
                    />
              
                </div>
                
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 5}}>
                    <Button variant="outlined" color='primary' style={{marginRight: 5, marginLeft: 5, backgroundColor: 'white'}} onClick={evaluate}>Evaluate</Button>
                    <TextField label="with Self"
                                variant="outlined" 
                                size="small"
                                style={{backgroundColor: 'white', marginRight: 40}}
                                value={self} 
                                inputProps={{style: {fontSize: 12}}}
                                InputLabelProps={{ shrink: true}} 
                                onChange={(event) => {
                                const userValue = event.target.value;
                                setSelf(userValue);
                            }}
                    />
                    {evalResult ?
                        <Typography variant='body2' style={{color: evalResult.error ? 'red' : 'black'}}>{evalResult.error ? evalResult.error : evalResult.result}</Typography>
                        : null
                    }
                    <Tooltip title="Used in some calculations. You may use the code {{year}} for the current year when Attribute is evaluated">
                        <TextField label="Attribute"
                                    variant="outlined" 
                                    size="small"
                                    fullWidth
                                    style={{backgroundColor: 'white', marginLeft: 'auto',  maxWidth: 400}}
                                    value={attribute} 
                                    InputLabelProps={{ shrink: true}} 
                                    onChange={(event) => {
                                    const userValue = event.target.value;
                                    setAttribute(userValue);
                                    props.calculation.attribute = userValue;
                                }}
                        />
                    </Tooltip>
                </div>
            </div>

            <div style={{marginRight: 5}}>
                <Tooltip title="When selected, this calculation contributes to the payment sum. Must evaluate to a Numeric value. Payment sum is in the Community's currency.">
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                        <Checkbox defaultChecked={isPaymentLineItem} color='primary' size='small'
                                    onChange={(event) => { 
                                        const userValue = event.target.checked; 
                                        setIsPaymentLineItem(userValue);
                                        props.calculation.isPaymentLineItem = userValue;
                                    }}/>                                  
                        <Typography variant='body1' align='left'>Payment Item</Typography>                        
                    </div>     
                </Tooltip>       

                <Tooltip title="When selected, this calculation value is included in any webhooks sent on Application submission. Should evaluate to Numeric or String value.">
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                        <Checkbox defaultChecked={isWebhookItem} color='primary' size='small'
                                    onChange={(event) => { 
                                        const userValue = event.target.checked; 
                                        setIsWebhookItem(userValue);
                                        props.calculation.isWebhookField = userValue;
                                    }}/>                                  
                        <Typography variant='body1' align='left'>Included in Webhook</Typography>                        
                    </div>     
                </Tooltip> 

                <Tooltip title='When selected, this calculation value determines the number of Limited Members to add. Should evaluate to a Numeric (integer) value. The attribute defines the Member fields separated by semicolons: "Last;First;Relation".'>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                        <Checkbox defaultChecked={addsLimitedMembers} color='primary' size='small'
                                    onChange={(event) => { 
                                        const userValue = event.target.checked; 
                                        setAddsLimitedMembers(userValue);
                                        props.calculation.addsLimitedMembers = userValue;
                                    }}/>                                  
                        <Typography variant='body1' align='left'>Adds Limited Members</Typography>                        
                    </div>     
                </Tooltip> 

                <Tooltip title='When selected, this calculation value determines how many guest passes to increment for the Membership. These are added after the the number set/incremented in the Community Settings. Should evaluate to a Numeric (integer) value.'>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                        <Checkbox defaultChecked={incrementsGuestPasses} color='primary' size='small'
                                    onChange={(event) => { 
                                        const userValue = event.target.checked; 
                                        setIncrementsGuestPasses(userValue);
                                        props.calculation.incrementsGuestPasses = userValue;
                                    }}/>                                  
                        <Typography variant='body1' align='left'>Increments Guest Passes</Typography>                        
                    </div>     
                </Tooltip> 
            </div>

            <div {...props.dragHandleProps} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 2, marginLeft: 4, backgroundColor: color, borderRight: '1px solid ' + ThemeColors.templateBorder}}>
                <div style={{float: 'right', marginRight: 5}}>
                    <Tooltip title={"Drag and move the calculation"}>
                        <PanToolIcon style={{float: 'right', color: 'gray', fontSize: 18}}/>
                    </Tooltip>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 24, backgroundColor: color}}>
                <Tooltip title="Delete this calculation">
                    <IconButton onClick={props.onDelete}>
                        <DeleteIcon style={{color: 'red', fontSize: 20}}/>
                    </IconButton>       
                </Tooltip> 
            </div>
        </div>
    );
    
}

/**
 * Render the calculation elements
 * @param {Array} calculations the array of Calculation objects
 * @param {Function} onChange called whenever a Calculation is added or deleted from the list
 * @param {Object} fieldTree used to pull variables for evaluations
 * @param {Function} convertToPostfixFunc called to request a infix to postfix conversion from the backend, passing the infix expression and a callback when fetch completes
 * @param {Function} getCalcResults function to call to retrieve all the other expression results
 * @returns {JSX}
 */
export function renderCalculations(calculations, onChange, fieldTree, convertToPostfixFunc, getCalcResultsFunc) {
    
    const addCalculation = () => {
        calculations.push({resultName: "Calculation" + String(calculations.length + 1), infixExpression: ""});
        onChange();
    };
    
    const deleteCalculation = (index) => {
        calculations.splice(index, 1);
        onChange();
    };
    
    const dragEnd = (result) => {
        
        const { destination, source } = result;
        
        if (!destination) //dropping over nothing
            return;
   
        //dropping over itself
        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;
       
        const moved = calculations.splice(source.index, 1);  //Remove from original position
        calculations.splice(destination.index, 0, moved[0]);  //Insert in new position
        
        onChange();
    };

    return (
        <Fragment>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 5, marginBottom: 5}}>
                <FieldCalculationTooltip title={fieldCalculationHelp()} interactive={true}>
                        <Typography variant="body2" style={{marginLeft: 8, fontSize: 14, fontWeight: 'bold', color: 'blue'}}>ⓘ</Typography>
                </FieldCalculationTooltip>
                <IconButton onClick={addCalculation}>
                    <AddCircleOutlineIcon style={{color: ThemeColors.addColor, fontSize: 18}}/>
                </IconButton>

            </div>
            <DragDropContext onDragEnd={dragEnd}>

                <Droppable droppableId={"calculations"}>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {calculations.map((calculation, index) => 
                                <Draggable draggableId={index.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps}>   
                                            <CalcTextField key={index + calculation.resultName} calculation={calculation} 
                                                          onDelete={() => deleteCalculation(index)} fieldTree={fieldTree} 
                                                          convertToPostifx={convertToPostfixFunc} calcResults={getCalcResultsFunc}
                                                          isDragging={snapshot.isDragging} dragHandleProps={provided.dragHandleProps}/>
                                        </div>
                                    )}  
                                </Draggable>
                            )}  
                            {provided.placeholder}
                        </div>                                    
                    )}
                </Droppable>

            </DragDropContext>
        </Fragment>
    );
   
    
}