import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
    
import { Tooltip, Typography, Paper, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Application } from '../models/Application';
import { renderField } from '../components/FieldRenderer';
import { renderCalculations } from '../components/CalculationRenderer';
import { FieldEditor } from '../components/FieldEditor';

import { refreshApplicationCalculations, computePaymentSum } from '../calculations/FieldCalculator';

import { ThemeColors } from '../Theme'

import { Template } from '../models/Template'
import { DocumentUploaderDownloaderComponent } from "../components/DocumentUploaderDownloaderComponent";

import { TextEntryPopover, Currency } from 'react-frontend-utils'



export class TemplatePage extends DocumentUploaderDownloaderComponent {
  
    styles = {
        paperLabel: {
            marginLeft: 5,
            color: 'gray',
            fontSize: '14pt',
            flexGrow: 1
        },
        paper: {
            padding: 10,
            minHeight: 500,
            backgroundColor: ThemeColors.templateGray
        },
        tab: {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            padding: 6
        },
        roundedContainer: {
            border: '1px solid #CCCCCC', 
            borderRadius: '4px', 
            padding: 10,
            marginBottom: 20
        },
        containerLabel: {
            fontSize: 12, 
            color: 'gray', 
            marginLeft: 5
        }
    };
    
    _fieldInClipboard = null;
    
    _fieldEditor = React.createRef();
 
    constructor(props) {
        super(props);
        this.state.templates = [];                  //All available templates
        this.state.selectedTemplateName = "";           //The currently displayed template name (use empty string, Autocomplete barfs on null 
        this.state.selectedTemplateNameValue = "";       
        this.state.creatingNew = false;
        
        this.state.selectedTemplate = null;        //The currently selected Template Object  
                
        this.state.namePopupOpen = false;
        
        this.state.fieldToEdit = null;    //The field whose properties are being edited
        
        this.state.tabDisplayed = "Form";
        
        this.state.applicationPreview = null;       //The previewed Application
        this.state.paymentSum = 0.0;                //The calculated payment sum
        this.state.paymentError = null;             //Error on payment calculation 

        this.state.submitTried = false;             //Tried a test submit
    }
    
    
   
    componentDidMount() {
        super.componentDidMount();
        this._fetchAll();
    }
    
    //Fetch all Templates 
    _fetchAll = () => {
        this.setState({tabDisplayed: "Form", creatingNew: false, selectedTemplate: null, selectedTemplateName: "",  selectedTemplateNameValue: ""}); 
        this._fetchTemplates();
    }
    
    _fetchTemplates = () => {
        
        this.incrementBusy();
        this.secureJSONFetch("/ap/templates", {},
                             this._fetchTemplatesCallback, this._fetchErrorCallback); 
        
    }
   
   
    //Callback for fetching Templates - response is a list of Template objects
    _fetchTemplatesCallback = (response) => {
        if (response) {            
            const templates = response.map(template => new Template(template));
            this.setState({templates: templates});
        }            
        this.decrementBusy();
    }
    

    _fetchErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }
  
  
    //Sent the template to the server for delete
    _delete = () => {
        if (this.state.selectedTemplate) {
            this.showConfirmAlert("Confirm", 'Really Delete Template "' + this.state.selectedTemplate.name + '"?',
                                  'black', "Cancel", this._deleteTemplate, "Delete", 'red');      
        }
    
    }
    
    _deleteTemplate = () => {
    
        if (this.state.selectedTemplate) {
            this.incrementBusy();
            this.secureJSONFetch("/ap/templates/" + this.state.selectedTemplate.name, {method: "DELETE"},
                             this._deleteCallback, this._fetchErrorCallback); 
            
            
        }
    }
    
    _deleteCallback = () => {
        this.showConfirmAlert("Success", "Template \"" + this.state.selectedTemplate.name + "\" deleted" , 'green'); 
        this.decrementBusy();
        this._fetchAll();
    }
  
  
    //Post the current application to the server for upserting
    _save = () => {
        if (this.state.selectedTemplate) {
        
            console.log("Modifying Template: ", this.state.selectedTemplate);
            this.state.selectedTemplate.prepareForPost();   //prepare the Template for uploading

            this.incrementBusy();
            this.secureJSONFetch("/ap/templates", {method: "POST", body: JSON.stringify(this.state.selectedTemplate)},
                             this._saveCallback, this._fetchErrorCallback); 
            
            
        }
    }
    
    
    _saveCallback = () => {
        this.showConfirmAlert("Success", "Template \"" + this.state.selectedTemplate.name + "\" " + (this.state.creatingNew ? "created" : "updated") , 'green'); 
        this.decrementBusy();
        this._fetchAll();
    }
  

    
    //Callback when a Template is selected from the available list, fetch the selected Template and all its fields
    _selectTemplate = (event, newValue) => { 
        
        const t = this.state.templates.find(t => t.name === newValue);
        if (!t)
            return;
      
        this.incrementBusy();
        this.secureJSONFetch("/ap/templates/" + t.name, {},
                             this._fetchTemplateCallback, this._fetchErrorCallback); 
        
    }
        
    _fetchTemplateCallback = (response) => {    
        
        if (response) {            
            const template = new Template(response);

            this.setState({selectedTemplateName: template.name, 
                           selectedTemplateNameValue: template.name, 
                           selectedTemplate: template, 
                           creatingNew: false,
                           fieldToEdit: null
                         }); 
                     
        }            
        this.decrementBusy();
    }
    
    
    //Called when editing the field value, and an error occurs
    _fieldInputError = (field, error) => {
        this.showConfirmAlert("Error for Field \"" + field + "\"", error, 'red');
    }
    
    
    _previewTabSelected = () => {
        
        this.setState({applicationPreview: null, paymentSum: 0.0, paymentError: null});  //clear old, so tab unmounts

        this.setState({tabDisplayed: "Preview"});
        
        if (this.state.selectedTemplate) {
            console.log("Previewing Template: ", this.state.selectedTemplate);
        
            this.incrementBusy();
            this.secureJSONFetch("/ap/templates/preview", {method: "POST", body: JSON.stringify(this.state.selectedTemplate)},
                             this._previewCallback, this._fetchErrorCallback); 
        
        }
        
    }
    
    _previewCallback = (response) => {    
        
        if (response) {            
            const applicationPreview = new Application(response, true); //for patron
            this.setState({applicationPreview: applicationPreview}); 
            this._previewFieldChanged();  //update all calculations
        }            
        this.decrementBusy();
    }
    
    
    
    _insertFieldFromClipboard = (parent) => {
        
        if (!this._fieldInClipboard)
            return;  //nothing on clipboard
        
        //We must walk the tree making sure we aren't going to duplicate a field name
        let hasDuplicate = false;
        do {
            hasDuplicate = Template.makeFieldNamesUnique(this.state.selectedTemplate.fieldTree, this._fieldInClipboard);
                 
        } while (hasDuplicate);
        
        //Make a copy
        const clonedClipboardField = JSON.parse(JSON.stringify(this._fieldInClipboard));


        //Insert the field from the clipboard
        if (parent.type === "FieldGroup" || parent.type === "PagedFieldGroup") 
            parent.children.push(clonedClipboardField);  //Insert at end    
        else if (parent.type === "CloningFieldGroup" && parent.template == null)  //only the first field can be inserted
            parent.template = clonedClipboardField;

        this.forceUpdate();
   
    }
    
  
    //Copy the field and place on Clipboard
    _copyField = (field) => {
        const clonedField = JSON.parse(JSON.stringify(field));
        this._fieldInClipboard = clonedField;
        this.forceUpdate();
    }


    //Remove the field from the tree and place on Clipboard
    _removeField = (field) => {
        
        if (this.state.fieldToEdit && this.state.fieldToEdit.name === field.name) {
            this.setState({fieldToEdit: null});  //field is being deleted, stop editing it
        }
        
        let removed;
        
        const result = Template.parentOfField(this.state.selectedTemplate.fieldTree, field);
        if (result) {
            const {parent, index} = result;
            if (parent.type === "FieldGroup" || parent.type === "PagedFieldGroup") {    
                removed = parent.children.splice(index, 1);  //Remove from child array
                this._fieldInClipboard = removed[0];
            }
            else if (parent.type === "CloningFieldGroup") {
                this._fieldInClipboard = parent.template;
                parent.template = null;  //remove template - its only child
            }
            else
                console.warn("Parent cannot be of type ", parent.type);
                        
            console.log(this._fieldInClipboard.name + " is now on the Clipboard");
            this.forceUpdate();
        }       
    }
    
    
    //Edit the value of a field
    _formFieldValueChanged = (field, newValue) => {
        console.log("Field \"" + field.name + "\" changing to " + newValue);
        this.forceUpdate();
    }
    
    
    _previewFieldChanged = (field, newValue) => {
        if (field)
            console.log("Field \"" + Application.uniqueFieldName(field) + "\" changing to " + (newValue ? newValue.substring(0, 100) : "null"));
        
        refreshApplicationCalculations(this.state.applicationPreview, (error) => this.showConfirmAlert("Error in Preview", error, 'red'));

        try {
            const paymentSum = computePaymentSum(this.state.applicationPreview);
            this.setState({paymentSum: paymentSum, paymentError: null});
        } catch (error) {
            this.setState({paymentSum: null, paymentError: error.toString()});
        }

        this.forceUpdate();
    }
    
    
    
    

    //This function must return an array of available fields, each item in the array having a label, icon, and a callback function.  The callback function should
    //(a) create a new field and (b) call the insertFunction to insert it into the Template 
    _getAvailableFields = (insertFunc) => {
        return Template.getAvailableFields(this.state.selectedTemplate.fieldTree, insertFunc);        
    }

    
    _createNew = () => {
        this.setState({namePopupOpen: true, creatingNew: true, fieldToEdit: null});  //creating new
    }
    
    _copyToNew = () => {
        this.setState({namePopupOpen: true, creatingNew: false});   //just renaming  
    }


    _export= () => {
        if (this.state.selectedTemplate) {
        
            console.log("Exporting Template: ", this.state.selectedTemplate);

            this.secureFileDownload("/ap/templates/" + this.state.selectedTemplate.name + "/export", this.state.selectedTemplate.name + ".json", 
                                    null, 
                                    (error) => this.showConfirmAlert("Error", error, 'red'));      
            
        }
    }


    _import = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            console.log("Selected file " + file.name);

            //Form data has the file as a multipart 
            const formData = new FormData();
            formData.append('file', file);

            this.secureFileUpload("/ap/templates/import",
                file,
                (isDone, status) => {  //always done, only a single response

                    const split = status.split(";");
                    const progressValue = split[0];
                    const errorMessage = split[1]; //error only on failure
            
                    if (progressValue < 0) //error
                        this.showConfirmAlert("Error", errorMessage, 'red');
            
                    else if (progressValue === "100") {
                        this.showConfirmAlert("Success", "Template uploaded, select it from the list to view" , 'green'); 
                        setTimeout(() => this._fetchTemplates());
                    }
                },
                (error) => this.showConfirmAlert("Error", error, 'red'));
        }
        event.target.value = null;  //allow picking of the same value again
    }
    
 
    _nameOkCallback = (name) => {
        
        if (!name) {
            this.showConfirmAlert("Error", "Name cannot be blank", 'red');   
            return;
        }

        const nameRegex = /^[a-zA-Z0-9 _]+$/
        const allowedNameChars = "A-Z a-z 0-9 _ and space";

        if (name.length > 254 || !name.match(nameRegex)) {
            this.showConfirmAlert("Template Name Error", "Name must not be longer than 254 characters, and must contain only the following characters: " + allowedNameChars, 'red');
            return;
        }

        
        if (this.state.creatingNew) {
            const newTemplate = Template.createNew(name);
                   
            this.setState({selectedTemplateName: name, 
                            selectedTemplateNameValue: name, 
                            selectedTemplate: newTemplate, 
                            namePopupOpen: false
                         }); 
        }
        else {  //copy
            this.state.selectedTemplate.copyToNew(name);
            this.setState({namePopupOpen: false, 
                            selectedTemplateName: name, 
                            selectedTemplateNameValue: name,
                            creatingNew: true});  //now we've copied, we are creating a new one          
        }
    }

    _nameCancelCallback = () => {
        this.setState({namePopupOpen: false, tabDisplayed: "Form", creatingNew: false, selectedTemplate: null, selectedTemplateName: "",  selectedTemplateNameValue: ""}); 
    }
    
    _calculationChanged = () => {
        this.forceUpdate();
    }
    
    _getAllCalculationResults = () => {
        //Grab all the computed results and place them in an array so some expressions can reference other results
        const calcResults = [];
        for (const calculation of this.state.selectedTemplate.fieldCalculations)
            calcResults.push({name: calculation.resultName, result: calculation.result});
        
        return calcResults;
    }
    
    
    //Send the expression to the backend to calculate the postfix expression for calculation
    _convertToPostfix = (infixExpression, onConverted) => {
        
        const queryString = "?infix=" + encodeURIComponent(infixExpression);
      
        this.incrementBusy();
        this.secureJSONFetch("/ap/postfix", {},
                             (response) => {this.decrementBusy();
                                            onConverted({result: response.postfixExpression});    //response is a FieldCalculation object
                                           },
                             (error) => {this.decrementBusy();
                                           onConverted({error: error});                                          
                                        },
                             queryString); 
                          
    }
   
    _fieldPropertyEdit = (field) => {
        
        //When first created the FieldEditor component receives the field prop, however since it makes use of uncontrolled components, if the field is changed
        //we must call the fieldPropertyEdit() on its reference to update the field explicity.
        if (this._fieldEditor.current)
            this._fieldEditor.current.fieldPropertyEdit(field);

        this.setState({fieldToEdit: field});        

    }


    _testSubmitApplication = () => {
        
        if (this.state.applicationPreview.isMissingData()) {
            this.showConfirmAlert("Missing Data", "One or more fields are required", 'red');
            this.setState({submitTried: true});
            return;
        }

        if (this.state.applicationPreview.hasErroredField()) {
            this.showConfirmAlert("Missing Data", "One or more fields have an error", 'red');
            this.setState({submitTried: true});
            return;
        }
        
        if (this.state.applicationPreview.isTooLarge()) {
            this.showConfirmAlert("Application too Large", "Your Application may be too large, you may need to remove some large attachments", 'red', 'Cancel', this._doSubmit, 'Try Anyway');
            return;
        }

        this.showConfirmAlert("Test Ok", "Application would be accepted", 'green');

    }
    
    _resetSubmit = () => {
        this.setState({submitTried: false});
    }
    
    
    //------------------------------- RENDER ----------------------------------------------
    
    render() {
       
        const buttonStyle = (activeColor, disabled) => {
            
            let color = disabled ? 'lightGray' : activeColor;
            return {borderColor: color, color: color, maxWidth: 100, textAlign: 'center'};
        };
       
        const showTemplate = this.state.selectedTemplateName || this.state.creatingNew ? true : false;
        const canCreateNew = this.state.creatingNew || this.state.selectedTemplate ? false : true;
        const canDelete = this.state.creatingNew || !this.state.selectedTemplate ? false : true;
                
        const templateNames = this.state.templates.map(template => template.name);
                
        const formRenderParams = {forPatron: false, 
                                  forTemplate: true, 
                                  documentUploader: this.documentUploader,
                                  documentDownloader: this.documentDownloader,
                                  canEdit: true, 
                                  onChange: this._formFieldValueChanged, 
                                  copyField: this._copyField,
                                  removeField: this._removeField, 
                                  clipboardField: this._fieldInClipboard,
                                  insertFieldFromClipboard: this._insertFieldFromClipboard,
                                  getAvailableFields: this._getAvailableFields,
                                  onFieldPropertyEdit: this._fieldPropertyEdit,
                                  fieldBeingEdited: this.state.fieldToEdit ? this.state.fieldToEdit.name : null,
                                  onError: this._fieldInputError};
                
        const previewRenderParams = {forPatron: true, 
                                     documentUploader: this.documentUploader,
                                     documentDownloader: this.documentDownloader,
                                     onChange: this._previewFieldChanged, 
                                     onError: this._fieldInputError};
        
                
                
        return (                        
             <Fragment>
                {this.getConfirmAlertComponent()}
                              
                <TextEntryPopover isOpen={this.state.namePopupOpen} showSkip={false} multiline={false} title="Template Unique Name" 
                                  okCallback={this._nameOkCallback} cancelCallback={this._nameCancelCallback} />
 
                <div style={{display: 'flex', gap: 20, marginLeft: 10, marginRight: 10, justifyContent: 'left', alignItems: 'center'}}>
                    <Autocomplete
                        size='small'
                        disabled={this.state.selectedTemplate !== null}
                        style={{width: '35%'}}
                        value={this.state.selectedTemplateName}
                        onChange={this._selectTemplate}
                        inputValue={this.state.selectedTemplateNameValue}
                        onInputChange={(event, newValue) => { this.setState({selectedTemplateNameValue: newValue}); }}
                        options={templateNames}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label="Templates" variant="outlined" InputLabelProps={{ shrink: true }} />}
                    />            
                
                    <Tooltip title="Save Changes and Refresh">
                        <Button disabled={!showTemplate} fullWidth size='small' style={buttonStyle('green', !showTemplate)} 
                                onClick={this._save} variant="outlined" component="label">
                            Save
                        </Button>
                    </Tooltip> 
                    <Tooltip title="Make a Copy of this Template with a new name">
                        <Button disabled={!showTemplate} fullWidth size='small' style={buttonStyle('blue', !showTemplate)} 
                                onClick={this._copyToNew} variant="outlined" component="label">
                            Copy
                        </Button>
                    </Tooltip>
                    <Tooltip title="Export this Template (last saved version) as a JSON text file">
                        <Button disabled={!showTemplate} fullWidth size='small' style={buttonStyle('blue', !showTemplate)} 
                                onClick={this._export} variant="outlined" component="label">
                            Export
                        </Button>
                    </Tooltip>  
                    <Tooltip title="Discard Changes and Clear">
                        <Button disabled={!showTemplate} fullWidth size='small' style={buttonStyle('black', !showTemplate)} 
                                onClick={this._fetchAll} variant="outlined" component="label">
                            Discard
                        </Button>
                    </Tooltip>
                    <Tooltip title="Create a new Template">
                        <Button disabled={!canCreateNew} fullWidth size='small' style={buttonStyle(ThemeColors.addColor, !canCreateNew)}
                                onClick={this._createNew} variant="outlined" component="label">
                            New
                        </Button>
                    </Tooltip> 
                    <Tooltip title="Import Template">
                        <Button disabled={!canCreateNew} fullWidth size='small' style={buttonStyle(ThemeColors.addColor, !canCreateNew)}
                                variant="outlined" component="label">
                            Import
                            <input accept="text/json" style={{display: 'none'}} type="file" onChange={this._import}/>
                        </Button>
                    </Tooltip>                                                       
                    <Tooltip title="Delete Template">
                        <Button disabled={!canDelete} fullWidth size='small' style={buttonStyle('red', !canDelete)}
                        onClick={this._delete} variant="outlined" component="label">
                            Delete
                        </Button>
                    </Tooltip>
                                    
                    {this.state.isBusy ? this.getBusyComponent('right', {marginLeft: 20}, 30) : null}

                </div>
                
                <div style={{marginTop: 15}}/>
                
                {this.state.selectedTemplate ?
                    
                    <div>
                        <div style={{display: 'flex', marginLeft: 20}}>
                            <div style={{...this.styles.tab, backgroundColor: this.state.tabDisplayed === "Form" ? ThemeColors.templateGray : ThemeColors.templateTab}}>
                                <Button fullWidth size='small' onClick={() => this.setState({tabDisplayed: "Form"})} style={{padding: 1}} component="label">
                                    Form
                                </Button>
                            </div>
                            <div style={{...this.styles.tab, marginLeft: 3, backgroundColor: this.state.tabDisplayed === "Calc" ? ThemeColors.templateGray : ThemeColors.templateTab}}>
                                <Button fullWidth size='small' onClick={() => this.setState({tabDisplayed: "Calc"})} style={{padding: 1}} component="label">
                                    Calculations
                                </Button>
                            </div>
                            <div style={{...this.styles.tab, marginLeft: 3, backgroundColor: this.state.tabDisplayed === "Preview" ? ThemeColors.templateGray : ThemeColors.templateTab}}>
                                <Button fullWidth size='small' onClick={this._previewTabSelected} style={{padding: 1}} component="label">
                                    Preview
                                </Button>
                            </div>
                        </div>
                        <Paper style={this.styles.paper}>   
                            <Typography style={this.styles.paperLabel} variant="h6">{(this.state.tabDisplayed === "Preview" ? "Previewing" : "Editing") + " \"" + this.state.selectedTemplate.name + "\""}</Typography> 
                            <div style={{display: this.state.tabDisplayed === "Form" ? 'flex' : 'none'}}>

                                <div style={{width: this.state.fieldToEdit !== null ? '70%' : '100%'}}>
                                    {renderField(this.state.selectedTemplate.fieldTree, 0, formRenderParams)}
                                </div>
                                <div>
                                    {this.state.fieldToEdit !== null ?
                                        <FieldEditor ref={this._fieldEditor} 
                                                     fieldToEdit={this.state.fieldToEdit} f
                                                     fieldWasEdited={() => this.forceUpdate()}
                                                     onClose ={() => this.setState({fieldToEdit: null})} />
                                        : 
                                        null
                                    }
                                </div>
                            </div>
                           
                            <div style={{display: this.state.tabDisplayed === "Calc" ? 'block' : 'none'}}>
                                {renderCalculations(this.state.selectedTemplate.fieldCalculations, this._calculationChanged, this.state.selectedTemplate.fieldTree, this._convertToPostfix, this._getAllCalculationResults)}
                            </div>
                            
                            
                            <div style={{display: this.state.tabDisplayed === "Preview" ? 'block' : 'none'}}>
                                <div style={{marginTop: 10, backgroundColor: 'white', border: '1px solid ' + ThemeColors.templateBorder}}>
                                    {this.state.applicationPreview ? renderField(this.state.applicationPreview.fieldTree, 0, previewRenderParams, this.state.submitTried) : null}
                                </div>

                                <div style={{marginTop: 20, backgroundColor: 'white', border: '1px solid ' + ThemeColors.templateBorder}}>
                                    {this.state.applicationPreview ? 
                                        <div style={{marginTop: 20, marginLeft: 10}}>
                                            <Typography variant="h6" >{"Calculations (" + this.state.applicationPreview.fieldCalculations.length + ")"}</Typography>
                                    
                                            {this.state.applicationPreview.fieldCalculations.map((calc, index) => 
                                                <Typography key={index} style={{marginTop: 5, color: calc.error ? 'red' : 'black'}} variant="body2">{calc.resultName + ": " + (calc.error ? calc.error : calc.result)}</Typography>
                                            )}
                                        </div>                     
                                         : <Typography align='center' variant="h6" style={{marginTop: 20, fontStyle: 'italic', color: 'gray'}}>Generating Preview...</Typography>
                                    }

                                    <Typography variant="h6" style={{marginTop: 20, marginLeft: 10, color: this.state.paymentError ? 'red' : 'black'}}>{"Calculated Payment: " + (this.state.paymentError ? this.state.paymentError : Currency.round(this.state.paymentSum))}</Typography>
                                </div>

                                <div style={{display: 'flex', justifyContent: 'center', gap: 10, marginTop: 20, marginBottom: 30}}>
                                    <Button variant='contained' color='primary' onClick={this._testSubmitApplication}>Test Submit</Button>
                                    <Button variant='outlined' onClick={this._resetSubmit}>Reset</Button>
                                </div>

                            </div>

                        </Paper>
                    </div>
                : null}
                
                
            </Fragment>
        );
        
    }
}


export default withCookies(withRouter(TemplatePage));




